<template>
    <b-modal
      v-model="data.showModal"
      size="md"
      button-size="sm"
      modal-class="template-modal"
      title-class="w-full"
      footer-class="w-full"
      no-fade
      @show="onShow"
      hide-header-close
      scrollable
    >
      <!-- Modal header -->
      <div slot="modal-title">
        <div class="flex w-full justify-between">
          Share note
        </div>
      </div>

      <!-- Modal content -->
      <div class="flex flex-col gap-2">
        <table class="table table-google">
          <tbody>
            <tr v-for="share in shares" :key="share.id">
              <td>{{ share.display_name }}</td>
              <td class="row-fit">
                <b-button
                  :variant="share.has_access ? 'primary' : 'white'"
                  size="sm"
                  @click="toggleShare(share)"
                  :disabled="share.busy"
                  class="w-20"
                >
                  <div v-if="share.has_access">Unshare</div>
                  <div v-else>Share</div>
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Modal footer -->
      <div slot="modal-footer" class="w-full d-flex gap-4 justify-content-between items-center">
        <div class="flex w-full justify-content-between">
          <div>
          </div>
          <div>
            <b-btn
              size="sm"
              variant="white"
              :disabled="isLoading"
              @click="onClose"
            >
              Close
            </b-btn>
          </div>
        </div>
      </div>
    </b-modal>
  </template>

<script>
export default {
  name: 'ShareModal',
  components: {},
  props: {
    data: Object,
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
    canSave() {
      return true;
    },
  },
  data() {
    return {
      loadingCount: 0,
      filters: {},
      options: {},
      shares: null,
    };
  },
  methods: {
    addShare(share) {
      share.busy = true;
      this.$http
        .post(`/notes/${this.data.note_id}/shares/${share.id}`)
        .then(() => {
          share.has_access = true;
          this.$emit('updated');
        })
        .catch((err) => {
          this.$toast.error(`Failed to add share: ${err.response.text}`);
        })
        .finally(() => {
          share.busy = false;
        });
    },
    removeShare(share) {
      share.busy = true;
      this.$http
        .delete(`/notes/${this.data.note_id}/shares/${share.id}`)
        .then(() => {
          share.has_access = false;
          this.$emit('updated');
        })
        .catch((err) => {
          this.$toast.error(`Failed to remove share: ${err.response.text}`);
        })
        .finally(() => {
          share.busy = false;
        });
    },
    toggleShare(share) {
      if (share.busy) {
        return;
      }
      if (share.has_access) {
        this.removeShare(share);
      } else {
        this.addShare(share);
      }
    },
    fetchShares() {
      this.loadingCount++;
      this.$http
        .get(`/notes/${this.data.note_id}/shares`)
        .then((res) => {
          this.shares = res.body.map((share) => ({
            busy: false,
            ...share,
          }));
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch shares: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchAll() {
      this.fetchShares();
    },
    onSave() {
      // Implement logic
    },
    onShow() {
      this.fetchAll();
    },
    onClose() {
      this.data.showModal = false;
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
</style>
